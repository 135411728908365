<template>
  <div :title="appInfo.name" @click.stop="appInfo.pkgId && $router.push(`/shop/detail/${appInfo.pkgId}`)" class="w-full flex justify-between items-center rounded-[12px] pb-0">
    <div class="flex items-center justify-between- w-full">
      <ion-img class="w-[56px] h-[56px] rounded-[12px] shrink-0 gray_border" :src="appInfo.icon" />
      <div class="w-full max-h-[56px] mx-[14px] overflow-ellipsis overflow-hidden">
        <h3 class="m-0 p-0 text-[16px] text-[#333333] font-medium w-full truncate">
          {{ appInfo.name!.trim() }}
        </h3>
        <p class="m-0 p-0 text-[12px] text-[#999999] mt-[2px] truncate">
          {{ !!appInfo?.brief && appInfo?.brief != '-' ? appInfo?.brief : appInfo?.category[0] }}
        </p>
      </div>
      <div class="w-[64px] shrink-0">
        <AppButton :app-id="appInfo.pkgId" :osDependence="appInfo.osDependence || ''" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonImg } from '@ionic/vue'
import { onMounted } from 'vue'

import AppButton from '@comp/AppButton.vue'
import { LazycatAppMetaModel } from '@type/models'

defineProps<{
  appInfo: LazycatAppMetaModel & { icon: string }
}>()

onMounted(() => {})
</script>
