<script setup lang="ts">
import { useRoot } from '@/store'
import { $$devSiteMode } from '@/utils/env'
import useSW from '@hooks/useSW'
import useWebviewVisible from '@hooks/useWebviewVisible'
import useWujieRouterChange from '@hooks/useWujieRouterChange'
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { useMediaQuery } from '@vueuse/core'
import { NConfigProvider, NMessageProvider } from 'naive-ui'
import { onMounted, provide } from 'vue'

import { renderIonRouter } from '@/router'

useSW()

const root = useRoot()

const isMinScreen = useMediaQuery('(max-width: 600px)')
provide('$$isMinScreen', isMinScreen)

async function beforeHook() {
  root.init()
}
console.debug('开始加载主页--')
useWebviewVisible(root.init)
useWujieRouterChange('appstore')
onMounted(beforeHook)
</script>

<template>
  <div id="app" v-if="!renderIonRouter" class="w-full h-full overflow-auto">
    <n-config-provider class="w-full h-full flex flex-col" preflight-style-disabled>
      <n-message-provider>
        <div id="wujie_inject_header" style="height: var(--wujie-header-height)" v-if="$$devSiteMode"></div>
        <div class="w-full h-0 grow">
          <router-view v-slot="{ Component }">
            <KeepAlive :include="['Layout']">
              <component :is="Component" />
            </KeepAlive>
          </router-view>
        </div>
      </n-message-provider>
    </n-config-provider>
  </div>
  <ion-app v-else class="font-sans">
    <ion-router-outlet />
  </ion-app>
</template>
